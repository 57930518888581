import { graphql } from 'gatsby';
import Template from './template';

export default Template;

export const pageQuery = graphql`
  query($id: String!) {
    cms: strapiPage(id: { eq: $id }) {
      ...StrapiPageFragment
    }
    labelsAndMessages {
      en
    }
  }
`;
