import React from 'react';
import { Container } from './default.styles';
import Shared, { IProps } from '../shared';

const Layout: React.FC<IProps> = ({
  children,
  location,
}) => (
  <Shared location={location}>
    <Container>
      {children}
    </Container>
  </Shared>
);

export default Layout;
