import React, { FC } from 'react';
import { PageProps } from 'gatsby';
import Layout from 'src/views/components/layout/default';
import {
  StrapiPageContext,
} from 'src/infrastructure/gatsby/node/create-pages/strapi-pages/types';
import { StrapiPage } from 'src/infrastructure/gatsby/types/strapi-page';
import PageBuilder from 'src/views/components/builders/page-builder';
import SEO from 'src/views/components/seo-tags';
import {
  LabelsAndMessages,
} from 'src/infrastructure/gatsby/types/label-and-messages';
import { Section } from 'src/infrastructure/gatsby/types/section-type';
import PageContextProvider from '../context';

type DataProps = {
  cms: StrapiPage;
  labelsAndMessages: Partial<LabelsAndMessages>;
}

const Template: FC<
  PageProps<DataProps, StrapiPageContext>
> = (props) => {
  const {
    location,
    pageContext: {
      locale,
    },
    data: {
      cms: {
        title,
        pageSettings,
        additionalPageSettings,
        seoSettings,
        sections,
        translationLinks = [],
        components,
      },
      labelsAndMessages,
    },
  } = props;

  if (!seoSettings) {
    throw new Error('No SEO settings provided!');
  }

  return (
    <PageContextProvider
      locale={locale}
      translationLinks={translationLinks}
      pageSettings={pageSettings}
      additionalPageSettings={additionalPageSettings}
      title={title}
      labelsAndMessages={labelsAndMessages}
    >
      <Layout location={location}>
        <SEO
          metaTitle={seoSettings.metaTitle}
          metaDescription={seoSettings.metaDescription}
          metaIndex={seoSettings.metaIndex}
          metaFollow={seoSettings.metaFollow}
          canonicalUrl={seoSettings.canonicalUrl}
          location={location}
          pageType="website"
          translationLinks={translationLinks}
        />
        <PageBuilder
          sections={sections}
          components={components as React.FC<Section>[]}
        />
      </Layout>
    </PageContextProvider>
  );
};

export default Template;
