/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from 'react';
import { Section } from 'src/infrastructure/gatsby/types/section-type';

interface IProps {
  sections?: Section[];
  components?: (React.FC<Section> | string)[];
}

const PageBuilder: FC<IProps> = (props) => {
  const { sections, components } = props;
  if (!sections) {
    throw new Error('No sections provided!');
  }

  return (
    <main data-test="sections">
      {sections && components ? components.map((Component, index) => {
        const { type, ...section } = sections[index];

        if (!Component) {
          // eslint-disable-next-line
          throw new Error('Component not injected!');
        }
        return (
          <div key={`${ type }_${ section.id }`}>
            <Component {...section} />
          </div>
        );
      }) : null}
    </main>
  );
};

export default PageBuilder;
